import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-loading',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './loading.component.html',
    styles: [
        `
            .shim-blue {
                position: relative;
                overflow: hidden;
                background-color: rgba(0, 155, 255, 0.7);
            }
            .shim-blue::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(
                    90deg,
                    rgba(233, 233, 233, 1) 0,
                    rgba(233, 233, 233, 0.9) 50%,
                    rgba(233, 233, 233, 0.8) 100%
                );
                animation: shimmer 2.5s ease-out infinite;
                content: '';
            }
            @keyframes shimmer {
                100% {
                    transform: translateX(0%);
                    opacity: 0;
                }
            }
        `,
    ],
})
export class LoadingComponent {}
