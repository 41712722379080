import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AfterContentChecked, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService, LanguageService } from '@lib/services';
import { LoadingService } from '@lib/services/loading';
import { SocketService } from '@lib/services/socket';
import { SubscriptionService } from '@lib/services/subscription';
import { ThemeService } from '@lib/services/theme';
import { LayoutWithSidebarComponent } from './lib/components/layouts/layout-with-sidebar/layout-with-sidebar.component';
import { LoadingComponent } from './lib/components/loading/loading.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterModule, HttpClientModule, LayoutWithSidebarComponent, LoadingComponent],
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterContentChecked {
    isAuthenticated$ = inject(AuthService).isAuthenticated$;
    isLoading$ = inject(LoadingService).isLoading$;

    private readonly _themeService = inject(ThemeService);
    private readonly _languageService = inject(LanguageService);
    private readonly _authService = inject(AuthService);
    private readonly _subscriptionService = inject(SubscriptionService);
    private readonly _socketService = inject(SocketService);
    private readonly _cdr = inject(ChangeDetectorRef);

    ngOnInit(): void {
        this._themeService.init();
        this._languageService.init();
        this._authService.init();
        // this._tutorialService.init();
        if (this.isAuthenticated$.getValue()) this._subscriptionService.init();
        this._socketService.init();
    }

    ngAfterContentChecked(): void {
        this._cdr.detectChanges();
    }
}
