import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, SecurityContext, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { jwtInterceptor, serverErrorInterceptor } from '@lib/interceptors';
import { socketConfig } from '@lib/services/socket/socket-config';
import { InputMaskModule } from '@ngneat/input-mask';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { MarkdownModule } from 'ngx-markdown';
import { SocketIoModule } from 'ngx-socket-io';
import { routes } from './app.routes';

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            InputMaskModule.forRoot({ inputSelector: 'input', isAsync: true }),
            SocialLoginModule,
            SocketIoModule.forRoot(socketConfig),
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: httpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
            MarkdownModule.forRoot({
                sanitize: SecurityContext.NONE,
            }),
        ),
        provideRouter(routes, withComponentInputBinding()),
        provideHttpClient(withInterceptors([serverErrorInterceptor, jwtInterceptor])),
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '687327088594-svcf07k6vmn8ujfel0fd2j0eithu4u2o.apps.googleusercontent.com',
                        ),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
};
